<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <v-btn
      slot="activator"
      color="primary"
      dark
      fixed
      bottom
      right
      fab
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-card>
        <v-card-title>
          <span class="headline">New Student Type</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="type"
                  label="Student Type"
                  :rules="typeRules"
                  :counter="20"
                  required
                />
              </v-flex>
              <v-flex
                xs12
                sm12
                md12
              >
                <v-textarea
                  v-model="description"
                  label="Brief Description of the this student type"
                  :rules="descRules"
                  :counter="200"
                  box
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            flat
            @click.native="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            flat
            @click.native="submit()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'StudentTypeNewDialogButton',
  data() {
    return {
      dialog: false,
      valid: true,
      type: '',
      typeRules: [
        v => !!v || 'Type is reqired',
        v => (v && v.length <= 20) || 'Type must be less than 20 characters',
      ],
      description: '',
      descRules: [
        v => (v && v.length <= 200) || 'Description must be less than 200 characters',
      ],
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('studentTypes/create', {
          type: this.type,
          description: this.description,
        })
          .then(() => {
            this.clear()
            this.dialog = false
          })
      }
    },
    clear() {
      this.$refs.form.reset()
    },
  },
}
</script>
