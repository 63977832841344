<template>
  <div>
    <v-card>
      <v-card-text>
        <!-- <student-fee-box :item="item"/> -->
        <v-layout wrap>
          <v-flex
            v-for="(fee, index) in fees"
            :key="index"
            sm4
          >
            <v-checkbox
              v-model="studFees"
              :label="fee.type"
              :value="fee"
              :loading="fee.loading"
              @change="change"
            />
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import StudentFeeSelectionBox from '@/components/student-type/StudentFeeSelectionBox.vue'

export default {
  name: 'StudentTypeFeeContent',
  components: {
  },
  props: ['item'],
  data() {
    return {
      studFees: [],
      oldSelection: [],
    }
  },

  computed: {
    fees() {
      return this.$store.getters['fees/all'] || []
    },

    studentFeeIDs() {
      return this.$store.getters['studentFees/getByTypeID'](this.item.typeID)
    },
  },

  watch: {
    item() {
      this.studFees = this.fees
        .filter(({ feeID }) => this.studentFeeIDs.includes(feeID))

      this.oldSelection = this.studFees
    },
  },

  created() {
    this.studFees = this.fees
      .filter(({ feeID }) => this.studentFeeIDs.includes(feeID))

    this.oldSelection = this.studFees
  },

  methods: {
    change(newValue) {
      if (newValue.length > this.oldSelection.length) {
        const eventItem = newValue.find(item => !this.oldSelection.includes(item))
        this.createStudFee(eventItem)
      } else {
        const eventItem = this.oldSelection.find(item => !newValue.includes(item))
        this.deleteStudFee(eventItem)
      }

      this.oldSelection = newValue
    },
    createStudFee(feeItem) {
      const fee = feeItem
      fee.loading = true
      this.$store.dispatch('studentFees/create', {
        typeID: this.item.typeID,
        feeID: feeItem.feeID,
      }).then(() => { fee.loading = false })
    },
    deleteStudFee(feeItem) {
      const fee = feeItem
      fee.loading = true
      this.$store.dispatch('studentFees/delete', {
        typeID: this.item.typeID,
        feeID: fee.feeID,
      })
        .then(() => { fee.loading = false })
    },
  },
}
</script>
