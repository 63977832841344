<template>
  <v-expansion-panel popout>
    <v-expansion-panel-content>
      <div slot="header">
        Fees
      </div>
      <student-type-panel-fee :item="item" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import StudentTypePanelFeeContent from '@/components/student-type/StudentTypePanelFeeContent.vue'

export default {
  name: 'StudentTypePanel',
  components: {
    'student-type-panel-fee': StudentTypePanelFeeContent,
  },
  props: ['item'],
  data() {
    return {

    }
  },
}
</script>
