<template>
  <v-flex xs12>
    <v-autocomplete
      v-model="selection"
      :disabled="isUpdating"
      :items="fees"
      box
      chips
      color="blue-grey lighten-2"
      label="Select Appicable Fees"
      item-text="type"
      item-value="feeID"
      multiple
      @input="change"
    >
      <template
        slot="selection"
        slot-scope="data"
      >
        <v-chip
          :selected="true"
          close
          class="chip--select-multi"
          @input="remove(data.item)"
        >
          {{ data.item.type }}
        </v-chip>
      </template>
      <template
        slot="item"
        slot-scope="data"
      >
        <template v-if="typeof data.item !== 'object'">
          <v-list-tile-content
            @click="select(data.item)"
            v-text="data.item"
          />
        </template>
        <template v-else>
          <v-list-tile-content>
            <v-list-tile-title
              @click="select(data.item)"
              v-html="data.item.type"
            />
          </v-list-tile-content>
        </template>
      </template>
    </v-autocomplete>
  </v-flex>
</template>

<script>
export default {
  props: ['item'],
  data() {
    return {
      autoUpdate: true,
      isUpdating: false,
      selectedItem: {},
      boxSelections: [],
      selection: [],
    }
  },

  computed: {
    fees() {
      return this.$store.getters['fees/all'] || []
    },

    studentFeeIDs() {
      return this.$store.getters['studentFees/getByTypeID'](this.item.typeID)
        // .filter(item => this.item.typeID === item.typeID)
        .map(item => item.feeID) || []
    },

    items() {
      return this.fees
        .filter(item => this.studentFeeIDs.every(id => item.feeID !== id)) || []
    },
  },

  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => { this.isUpdating = false }, 3000)
      }
    },
    selection(newValue, oldValue) {
      if (newValue.length > oldValue.length) {
        const feeID = newValue.find(item => !oldValue.includes(item))
        this.$store.dispatch('studentFees/create', {
          typeID: this.item.typeID,
          feeID,
        })
      } else {
        const feeID = oldValue.find(item => !newValue.includes(item))
        this.$store.dispatch('studentFees/delete', {
          typeID: this.item.typeID,
          feeID,
        })
      }
    },
    item() {
      this.selection = this.fees
        .filter(item => this.studentFeeIDs.some(id => item.feeID === id))
    },
  },

  methods: {
    remove(item) {
      const index = this.selection.indexOf(item.name)
      if (index >= 0) this.selection.splice(index, 1)
    },
    select(item) {
      this.selectedItem = item
    },
  },
}
</script>
