<template>
  <v-container
    grid-list-md
    text-xs-center
  >
    <v-layout wrap>
      <v-flex
        v-for="(item, i) in items"
        :key="i"
        xs6
        sm3
        md12
        @click="select(item)"
      >
        <v-card :dark="item === selected">
          <v-card-text class="px-0">
            {{ item.type }}
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'StudentTypeList',
  data() {
    return {
      selected: {},
    }
  },
  computed: {
    items() {
      return this.$store.getters['studentTypes/all'] || []
    },
  },
  watch: {
    selected(value) {
      this.$emit('valueChange', value)
    },
  },
  created() {
    this.$store.dispatch('studentTypes/init')
  },
  methods: {
    select(item) {
      this.selected = item
    },
  },
}
</script>
