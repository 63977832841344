<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex
        xs12
        sm12
        md12
      >
        <student-type-new-button />
      </v-flex>
      <v-flex
        xs3
        sm3
        md3
      >
        <student-type-list @valueChange="select" />
      </v-flex>
      <v-flex
        xs9
        sm9
        md9
      >
        <student-type-panel
          v-if="selectedItem"
          :item="selectedItem"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import StudentTypeNewDialogButton from '@/components/student-type/StudentTypeNewDialogButton.vue'
import StudentTypeList from '@/components/student-type/StudentTypeList.vue'
import StudentTypePanel from '@/components/student-type/StudentTypePanel.vue'

export default {
  name: 'StudentType',
  components: {
    'student-type-new-button': StudentTypeNewDialogButton,
    'student-type-list': StudentTypeList,
    'student-type-panel': StudentTypePanel,
  },
  data() {
    return {
      selectedItem: null,
    }
  },
  methods: {
    select(item) {
      this.selectedItem = item
    },
  },
}
</script>
